 <template>
  <div>
    <detail-header></detail-header>
    <div class="section">
      <h2 class="title">柜子信息 <el-button type="primary" @click="initData" style="float:right;">刷新</el-button></h2>
      <el-form>
        <el-row>
          <el-col :span="8">
            <el-form-item label="柜子编号：">
              {{ info.stationMacid }}</el-form-item
            >
          </el-col>

          <el-col :span="8">
            <el-form-item label="换电阈值："> {{ info.tranSoc }}%</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="音量："> {{ info.vol }}%</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="机柜总电压：">
              {{ info.BetteryVAll }}V
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="机柜总电流：">
              {{ info.dpower }}A
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="柜内温度：">
              {{ info.temperature }}℃
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="sim卡：">
              {{ info.imsi }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="软件版本：">
              {{ info.softVersion }}
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="硬件版本：">
              {{ info.hardVersion }}
            </el-form-item>
          </el-col>

           <el-col :span="8">
            <el-form-item label="电表读数："> {{ info.usepower }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 仓门 -->
    <div class="section">
      <h2 class="title">仓门信息</h2>
      <el-table
        border
        :data="list"
        class="page-top-space"
        row-class-name="table-row"
      >
        <el-table-column label="仓门" prop="roomCode"></el-table-column>
        <el-table-column label="电池编号" prop="macId">
          <template #default="scope">
            <span>{{ scope.row.macId ? scope.row.macId : "-" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="电量" prop="macId">
          <template #default="scope">
            <span>{{ scope.row.macId ? scope.row.soc + "%" : "-" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="电压" prop="macId">
          <template #default="scope">
            <span>{{ scope.row.macId ? scope.row.voltage + "V" : "-" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="电流" prop="macId">
          <template #default="scope">
            <span>{{
              scope.row.macId ? scope.row.electricity + "A" : "-"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="是否开门" prop="macId">
          <template #default="scope">
            <eb-badge
              :list="this.const.BOX_DOOR_OPEN"
              :target="scope.row.isOpen"
            ></eb-badge>
          </template>
        </el-table-column>

        <el-table-column label="是否禁用" prop="macId">
          <template #default="scope">
            <eb-badge
              :list="this.const.BOX_DOOR_BAN"
              :target="scope.row.stopStatus"
            ></eb-badge>
          </template>
        </el-table-column>

        <el-table-column label="操作" prop="operation">
          <template #default="scope">
            <span
              class="option option-primary"
              @click="doOpendoor(scope.row.roomCode)"
              >打开仓门</span
            >
            <span
              v-if="
                this.const.BOX_DOOR_BAN_CODE.NORMAL === scope.row.stopStatus
              "
              class="option option-primary"
              @click="doBoxSetting(scope.row.roomCode, SETTING_VALUE.BAN)"
              >禁用仓门</span
            >
            <span
              v-if="
                this.const.BOX_DOOR_BAN_CODE.IS_BAN === scope.row.stopStatus
              "
              class="option option-primary"
              @click="
                doBoxSetting(scope.row.roomCode, SETTING_VALUE.REMOVE_BAN)
              "
              >解除禁用</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  fetchCabinetBoxInfo,
  batteryListByStation,
  fetchCabinetOnline,
  fetchStationBatteryList,
  fetchCabinetInfo,
  btaUpdateSetting,
  btaOpenDoor,
} from "@/api/station";

import { fetchGPSInfo } from "@/api/battery";

export default {
  components: {},
  data() {
    let stationMacid = this.$router.currentRoute.value.params.id;
    return {
      SETTING_VALUE: {
        BAN: "06",
        REMOVE_BAN: "07",
      },
      SIGN: "02301001",
      stationMacid: stationMacid,
      info: {},
      list: [],
      query: {
        soc: -1,
        roomCode: "",
        isOpen: -1,
        batteryStatus: -1,
        hasBattery: -1,
        name: "",
        nums: -1,
        stationId: -1,
        info: "",
        stationMacid: stationMacid,
      },
    };
  },
  methods: {
    // 打开仓门
    doOpendoor(doorId) {
      this.$confirm("是否要打开仓门", "提示").then((res) => {
        btaOpenDoor({
          adminId: this.$store.state.user.userInfo.id,
          stationMacid: this.stationMacid,
          type: this.const.STATION_TYPE_CODE.EXCHANGE_CABINET,
          doorId: doorId,
        }).then((res) => {
          setTimeout(() => {
            this.initData();
          }, 1000);
        });
      });
    },

    // 禁用设置
    doBoxSetting(doorId, type) {
      btaUpdateSetting({
        type: this.const.STATION_TYPE_CODE.EXCHANGE_CABINET,
        signalId: this.SIGN,
        adminId: this.$store.state.user.userInfo.id,
        stationMacid: this.stationMacid,
        doorId: doorId,
        value: type,
      }).then((res) => {
        setTimeout(() => {
          this.initData();
        }, 500);
      });
    },

    initData() {
      fetchCabinetBoxInfo(this.query).then((res) => {
        let list = res.data.data.records;
        if (list.length === 0) {
          this.$message({
            message: "没有注册格子",
            type: "warning",
          });
          return;
        }
        // 查询站点电池
        fetchStationBatteryList({
          size: 20,
          pageIndex: 1,
          query: {
            operationId: -1,
            status: 1, // 只查询在库的
            type: -1,
            outAdminId: -1,
            createAt: "",
            inAdminId: -1,
            deviceUserId: -1,
            outTime: "",
            stationId: list[0].stationId,
          },
        }).then((stationRes) => {
          let cabinetList = stationRes.data.data.records;
          let macids = [];
          cabinetList.map((item) => {
            list.map((boxItem) => {
              if (item.cabinet.roomCode === boxItem.roomCode) {
                boxItem.macId = item.macid;
                macids.push(item.macid);
              }
            });
          });
          fetchGPSInfo(macids).then((batteryRes) => {
            let macidsoc = batteryRes.data.data.records;
            macidsoc.map((item) => {
              list.map((listItem) => {
                if (item === listItem.macId) {
                  listItem.soc = item.SOC;
                  listItem.btType = parseFloat(item.FCC) > 27 ? 4830 : 4820;
                }
              });
            });

            this.list = list;
            console.log(this.list, "this.list");
          });
        });
      });
    },

    // 获取站点详细信息
    getStationInfo() {
      fetchCabinetInfo(this.stationMacid).then((res) => {
        this.info = res.data.data;
      });
    },
  },

  mounted() {
    this.initData();
    this.getStationInfo();
  },
};
</script>

<style scoped>
.section {
  border-bottom: 1px solid #e1e1e1;
  padding: 30px 10px;
}
.section .title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}
</style>